const Company = [
  {
    name: "GADHINGLAJ PHARMA LLP",
    fullName: "GADHINGLAJ PHARMA LLP",
    phone: {
      primary: [
        {
          label: "primary",
          number: "02313-500499",
        },
      ],
      alternate: [
        {
          label: "COMPLAINT NO:",
          icon: "mobile",
          number: "2313-500499",
        },
        {
          label: "ORDER:",
          icon: "receiver",
          number: "2313-500500",
        },
        {
          label: "EXPIRY:",
          icon: "receiver",
          number: "2313-500502",
        },
        {
          label: "ACCOUNTS:",
          icon: "receiver",
          number: "2313-500501",
        },
        {
          label: "DISPATCH:",
          icon: "receiver",
          number: "2313-500503",
        },
      ],
    },
    email: "gadllp21@gmail.com",
    address:
      "HANJI BUILDING,BEHIND YASHONAND HOTEL AVANIL HANJI COLONY,BHADGAON ROAD GADHINGLAJ-416502-KOLHAPUR (MAHARASHTRA)",
  },
];

const BankDetails = [
  {
    label: "Bank Details:",
    value: "SHRI VEERSHAIV CO-OP-BANK LTD",
  },
  {
    label: "Branch:",
    value: "GADHINGLAJ",
  },
  {
    label: "A/C. No.:",
    value: "003010600001359",
  },
  {
    label: "IFSC Code:",
    value: "SVSH0000003",
  },
];

export { Company, BankDetails };