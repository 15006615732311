import React from 'react'
import { Container, Nav, NavDropdown, Navbar } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import img2 from "../Images/logo.png";
import '../Styles/header.scss'
import { Company } from '../assets/Data'

function Header({ currentComponent, handleNavLinkClick }) {
  const history = useHistory();
    const linkStyle = {
      fontSize: 16,
    };

    const DropdownLinkStyle = {
      fontSize: 16,
      fontWeight: 400,
    };

  const CompanyData = Company[0];

  return (
    <>
      <header className="main">
        <div className="row m-0">
          <div className="column col-md-1 col-12 p-0 d-flex justify-content-start">
            <img src={img2} className="logo" alt={CompanyData.name + " logo"} />
          </div>

          <div className="column col-md-3 col-12 justify-content-center">
            <a className="text-decoration-none" href="/#Home">
              <h1 className="pl-lg-3 headerH1">{CompanyData.name}</h1>
            </a>
          </div>

          <div className="column col-md-3 col-12  d-flex justify-content-center">
            <span className="phone">
              <FontAwesomeIcon icon={faPhone} />
              {CompanyData.phone.primary[0].number}
            </span>
          </div>

          <div className="column col-md-4 col-12  d-flex justify-content-center   ">
            <span className="email">
              <FontAwesomeIcon icon={faEnvelope} />
              {CompanyData.email}
            </span>
          </div>
        </div>
      </header>

      <Navbar expand="lg" style={{ padding: 0 }}>
        <Container fluid className="navigation-bar container">
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav
              className="me-auto my-2 my-lg-0 container "
              style={{
                display: window.innerWidth < 1024 ? "flex" : "contents",
                flexDirection: "column",
                flexWrap: "nowrap",
              }}
              navbarScroll
            >
              <Nav.Link
                className={
                  (currentComponent === "Home" || currentComponent === "home"
                    ? "activeLink"
                    : "") + " menu-link linksPadding "
                }
                style={linkStyle}
                onClick={() => handleNavLinkClick("Home")}
              >
                Home
              </Nav.Link>

              <Nav.Link
                className={
                  (currentComponent === "AboutUs" ? "activeLink" : "") +
                  " menu-link linksPadding"
                }
                style={linkStyle}
                onClick={() => handleNavLinkClick("AboutUs")}
              >
                About Us
              </Nav.Link>

              <Nav.Link
                className={
                  (currentComponent === "OurVision" ? "activeLink" : "") +
                  " menu-link linksPadding"
                }
                style={linkStyle}
                onClick={() => handleNavLinkClick("OurVision")}
              >
                Our Vision
              </Nav.Link>

              <Nav.Link
                className={
                  (currentComponent === "OurValues" ? "activeLink" : "") +
                  " menu-link linksPadding"
                }
                style={linkStyle}
                onClick={() => handleNavLinkClick("OurValues")}
              >
                Our Values
              </Nav.Link>

              <Nav.Link
                className={
                  (currentComponent === "ContactUs" ? "activeLink" : "") +
                  " menu-link linksPadding"
                }
                style={linkStyle}
                onClick={() => handleNavLinkClick("ContactUs")}
              >
                Contact Us
              </Nav.Link>

              <Nav.Link
                className=" menu-link linksPadding"
                onClick={() => history.push("/ProductList")}
                style={linkStyle}
              >
                Products
              </Nav.Link>
            </Nav>

            <Nav
              // className="d-flex linksPadding "
              style={{
                position: window.innerWidth > 768 ? "absolute" : false,
                right: 0,
              }}
              navbarScroll
            >
              <NavDropdown
                title="Login As"
                style={{
                  marginRight: "20px",
                  fontSize: 16,
                }}
                className="linksPadding"
                align={"end"}
                id="navbarScrollingDropdown"
              >
                <NavDropdown.Item
                  style={DropdownLinkStyle}
                  onClick={() => history.push("/Customer")}
                >
                  Customer
                </NavDropdown.Item>
                <NavDropdown.Item
                  style={DropdownLinkStyle}
                  onClick={() => history.push("/Company")}
                >
                  Company
                </NavDropdown.Item>
                {/* <NavDropdown.Item
                  style={DropdownLinkStyle}
                  onClick={() => history.push("/Salesman")}
                >
                  Salesman
                </NavDropdown.Item> */}
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default Header