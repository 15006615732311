import React from "react";
import { useState } from "react";
import LoaderTri from "../../components/Loader/LoaderTri";
import { Image } from "antd";
import { Company } from "../../assets/Data";

const ContactUs = () => {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <div className="container py-5">
      <div className="row">
        <div className="col-md-6 col-sm-12">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7662.107313729113!2d74.347921!3d16.217681!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc08f1224648faf%3A0x1a3f8f192bdee2a9!2sGADHINGLAJ%20PHARMA%20LLP!5e0!3m2!1sen!2sin!4v1700212422717!5m2!1sen!2sin"
            width="100%"
            height="450"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>

        <div className="col-md-6 col-sm-12">
          <div className="my-5 mx-4 contact-info">
            <p
              className="fw-bold m-0"
              dangerouslySetInnerHTML={{ __html: Company[0].address }}
            ></p>
          </div>

          <div className="contact mx-4">
            {Company[0].phone.alternate.length > 0 &&
              Company[0].phone.alternate.map((item, index) => (
                <p className="fw-bold m-0" key={index}>
                  {index === 0 ? "Ph.: " : ""}
                  {item.label} {item.number}
                </p>
              ))}
            {Company[0].email != "" && (
              <p className="fw-bold m-0">Email: {Company[0].email}</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
