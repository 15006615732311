import React from "react";
// import Slider from "./Slider";
// import MarqueeLComList from "./MarqueeLComList";
// import { BackTop } from "antd";
import HomeImg from '../../../Images/3.jpg'

const HomeComponent = () => {
  return (
    <div className=" w-100">
      <img src={HomeImg} alt="banner" className="img-fluid" />
    </div>
  );
};

export default HomeComponent;
